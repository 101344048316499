
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import type ClusterService from '@/modules/cluster/cluster.service';
import type ProvidersService from '@/modules/providers/providers.service';

@Component({
    components: { CustomSelect },
})
export default class MarketsChainProviderTypeFilter extends Vue {
    @inject(KEY.ClusterService)
    private clusterService!: ClusterService;

    @inject(KEY.ProvidersService)
    private providersService!: ProvidersService;

    get provider() {
        return this.clusterService.currentMarketsProvider;
    }

    set provider(value) {
        this.clusterService.saveCurrentProvider(value);
    }

    get providerItems(): Item[] {
        const { marketProviders } = this.providersService.userProviders;

        return this.providersService
            .toItemsList(marketProviders);
    }
}
